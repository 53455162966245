import dayjs from '~/utils/dayjs';

export const DISPLAY_DATE_FORMAT = 'DD.MM.YYYY';
export const DISPLAY_DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm';
// This util is only used when we need to display date in UI
// Don't use it with VCalendar input because
// VCalendar needs v-modal to be a Date
// and it has strange format syntax which we avoid using locale

export const toDisplayDate = (
  date: Parameters<typeof dayjs>[0],
  time = false,
) =>
  dayjs(date).format(
    time ? DISPLAY_DATE_FORMAT_WITH_TIME : DISPLAY_DATE_FORMAT,
  );
